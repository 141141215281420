$background-color: #79006e;
$background-color-hover: #ff00ea;

.Package {
    width: 300px;
    flex: 1 0 250px;
    box-shadow: 0 1px 1rem -4px #000;
    background: #fff;
    margin: 1rem;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    height: 450px;


    @media (max-width: 400px) {
        width: 250px;
    }

    &:hover {
        transform: scale(1.1, 1.1);
        transition: 0.5s;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.30);

    }

    .package-image {
        width: 100%;
        height: 190px;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .package-heading{
        height: 100px;
    }

    .badge-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .badge {
            display: flex;
            align-items: center;
            background-color: rgb(230, 230, 230);
            color: black;
            margin: 3px;
            font-weight: 500;
        }
    }

    .button {
        background-color: white;
        color: $background-color;
        border: 1px $background-color solid;
        border-radius: 20px;
        padding: 5px 15px 5px 15px;

        &:hover {
            background-color: $background-color;
            color: white;
        }
    }

    .Expore-div {
        color: $background-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}