$background-color: #79006e;

.Blog {
    // border: 1px gray solid;
    width: 400px;
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.30);
    cursor: pointer;
    transition: all 250ms ease-in-out;

    @media (max-width: 980px) {
        width: 350px;
    }

    @media (max-width: 500px) {
        width: 280px;
    }

    &:hover {
        transform: scale(1.1, 1.1);
        transition: 0.5s ease-in-out;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.30);
    }

    .image {
        width: 100%;
        height: 180px;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .content {
        text-align: start;
        padding: 20px;

        .heading {
            height: 90px;
            text-wrap: wrap;
            font-size: 1.5rem;
            font-weight: 600;

            @media (max-width: 500px) {
                font-size: 1.2rem;
                font-weight: 600;
            }
        }

        .read-more-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $background-color;
        }
    }
}