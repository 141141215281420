$background-color: #79006e;
$background-color-hover: #ff00ea;

.Welcome {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 70px;

    @media (max-width: 400px) {
        margin: 30px;
    }

    .text-container {
        width: 50%;

        .heading {
            font-size: 3rem;
            font-weight: 600;
            font-variant: normal;
        }
    }

    .images {
        position: relative;
        width: 50%;
        height: 500px; /* Adjust the height as per your requirement */

        .image-1 {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            width: 60%;
            height: 60%;
            box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30);
            object-fit: cover; /* Ensure the image covers the entire container */
        }

        .image-2 {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 75%;
            height: 75%;
            box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30);
            object-fit: cover; /* Ensure the image covers the entire container */
        }
    }

    @media (max-width: 1200px) {
        display: block;

        .text-container {
            width: 100%;
            // margin: 40px 20px 40px 20px;
        }
        .images{
            width: 100%;

            .image-1 {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
            }
    
            .image-2 {
                display: none; /* Hide image2 at 1200px */
                width: 0;
                height: 0;
                overflow: hidden;
            }
        }
      }
}