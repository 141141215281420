.Gallery{
    .gallery-container {
        // margin: 8% 50px;

        .gallery-heading-container {
            color: white;
            padding: 20% 0;
            background-image: url('https://www.kosha.co/journal/wp-content/uploads/2021/11/Experience-Leh-in-Winter.jpg');
            text-align: center;
            margin-bottom: 3%;
            width: 100%;
            background-size: cover;
            background-position: center;

            @media (min-width: 590px) {
                padding: 10% 0;
            }

            .heading {
                font-size: 2.3rem;
                font-weight: bold;
                text-shadow: 2px 2px black;
            }

            .paragraph {
                font-size: 1rem;
                font-weight: 600;
                text-shadow: 1px .5px black;
                margin: 5px;
            }
        }

        .gallery-component-container{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin: 40%;

            .gallery-component{
                width: 50%;

                @media (max-width: 760px) {
                width: 100%;
                    
                }
    
            }
        }
    }
}