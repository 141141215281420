.GalleryHeading {
    flex: 1 0 250px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 630px) {
        margin: 1px;
    }

    .image-container {
        position: relative;
        display: flex; /* Added to allow flexible sizing */

        .image {
            width: 300px;
            height: 350px;
            background-size: cover;
            background-position: center;
            border-radius: 20px;
            transition: width 0.5s ease; /* Added transition property */

            @media (max-width: 630px) {
                width: 200px;
                height: 250px;
            }
        }

        .image-text-conatainer {
            color: white;
            position: absolute;
            bottom: 10%;
            left: 10%;
            z-index: 9999;

            .heading {
                font-size: 40px;
                font-weight: 600;
                transition: 1s ease-in-out;
            }

            .sub-heading {
                font-size: 20px;
            }
        }

        .hover-items {
            display: none;
            position: absolute;
            top: 0;
            right: -300px; /* Start initially outside the container */
            width: 300px; /* Set initial width */
            height: 100%; /* Occupy full height */
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.662);
            color: white;
            overflow: hidden;
            transition: right 0.5s ease-out, background-color 0.5s ease-out, width 0.5s ease; /* Added width transition */

            @media (max-width: 630px) {
                width: 200px;
            }


            .hover-items-text {
                font-size: 3rem;
                font-weight: 600;
                padding-top: 30%;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 630px) {
                    font-size: 2rem;
                    font-weight: 500;
                    padding-top: 50%;
                }
            }
        }

        &:hover {
            .image-text-conatainer {
                display: none;
            }
            .hover-items {
                display: block;
                right: 0; /* Slide in from the right */
                background-color: rgba(0, 0, 0, 0.634);
                width: 100%; 
                animation: slideIn 0.5s ease-in-out forwards;
            }
            .image {
                width: 450px; 

                @media (max-width: 630px) {
                    width: 200px;
                }
            }
        }
    }
}

@keyframes slideIn {
    0% {
        height: 0;
        top: 100%;
    }
    100% {
        height: 100%;
        top: 0;
    }
}
