$background-color: #79006e;
$background-color-hover: #ff00ea;

.FeedBack{
    position: fixed;
    z-index: 99999;
    top: 70%;
    right: -35px;
    background-color: $background-color;
    color: white;
    padding: 10px 20px 10px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    rotate: 270deg;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.50);
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover{
        // background-color: $background-color-hover;
        transform: scale(1.2,1.2);
    }
}