$background-color: #79006e;
$background-hover-color: #55004e;

.Packages {

    .package-container {

        .package-heading-container {
            color: white;
            padding: 20% 0;
            background-image: url('https://www.kosha.co/journal/wp-content/uploads/2021/11/Experience-Leh-in-Winter.jpg');
            text-align: center;
            margin-bottom: 3%;
            width: 100%;
            background-size: cover;
            background-position: center;

            @media (min-width: 590px) {
                padding: 10% 0;
            }

            .heading {
                font-size: 2.3rem;
                font-weight: bold;
                text-shadow: 2px 2px black;
            }

            .paragraph {
              font-size: 1rem;
              font-weight: 600;
              text-shadow: 1px .5px black;
              margin: 5px;
          }
        }
        .main-popup-container{
          padding: 5px;
          position: fixed;
          height: 110%;
          top: -9%;
          left: 0;
          right: 0;
          z-index: 999999998;
          border-radius: 20px;
          margin: 90px 20px;
          // overflow: auto;

          .popup-container{
              overflow: auto;
              height: 100%;
  
              .button-container{
                  margin: 20px;
                  position: absolute;
                  top: 0;
                  right: 0;
                  z-index: 999999999;
                  font-size: x-large;

                  .btn-close{
                      color: white !important;
                  }
              }
  
              .popup-content{
                  height: 85%;
                  overflow: auto;
          box-shadow: 0 6px 10px rgba(0, 0, 0, 0.50);

              }
          }
      }
    }
    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
      
        .btn-style {
          background-color: black;
          // color: $main-text-color;
          // border-color: $button-color;
          font-weight: 500;
      
          &:hover {
            background-color: red;
          }
        }
      
        li {
          margin: 0 5px;
          display: flex;
      
          a {
            display: block;
            padding: 8px 12px;
            text-decoration: none;
            color: white;
            border: 1px solid #ccc;
            border-radius: 50%;
            background-color: $background-color;
      
            &:hover {
              background-color: $background-hover-color;
            }
          }
      
          &.active a {
            background-color: #36454F !important;
            color: #fff !important;
            border-color: #36454F !important;
          }
      
          &.disabled a {
            pointer-events: none;
            opacity: 0.6;
          }
      
          &.break-me a {
            cursor: default;
          }
      
        }
      
        @media (max-width: 768px) {
          flex-wrap: wrap;
      
          li {
            margin: 5px;
          }
        }
      }
}