.PackageManagement{

    .add-popup{
        display: flex;
        align-items: center;
        justify-content: center;
    
        .package-acivities{
            border: 1px rgb(103, 103, 103) solid;
            border-radius: 20px;
            background-color: rgb(233, 233, 233);
            margin: 10px 0;
        }
    }

}
