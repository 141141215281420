$background-color: #79006e;
$background-color-hover: #ff00ea;

.ReactSlider {
    width: 100%;

    .slider {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: relative;

        .wrapper {
            height: 80vh;
            transition: .5s all linear;
            will-change: transform;
        }

        .arrows {
            // margin: 20px;
            position: absolute;
            top: 38%;
            height: 60px;
            border: 0;
            cursor: pointer;
            transition: ease .3s all;
            outline: none;
            color: white;
            font-size: 50px;

            svg {
                filter: brightness(0.5) grayscale(100%);
            }

            &.prev {
                left: 0;
                z-index: 9998;

                &:hover {
                    opacity: .7;
                    left: -10px;
                }
            }

            &.next {
                z-index: 9998;
                right: 0;

                &:hover {
                    right: -10px;
                    opacity: .7;
                }
            }
        }

        .dots-container {
            height: auto;
            margin: 0;
            padding: 0;
            position: absolute;
            width: auto;
            text-align: center;
            left: 50%;
            top: 80%;
            bottom: 9px;
            transform: translateX(-50%);
            z-index: 9997;
            list-style-type: none;

            @media (max-width: 400px) {
                top: 79%;
            }

            li {
                display: inline-block;
                padding: 5px;
                align-items: center;
                font-size: x-large;

                &.active {
                    button {
                        color: $background-color;
                        transform: scale(1.5, 1.5);
                        transition: .3s ease-in-out;
                    }
                }

                button {
                    color: #fff;
                    background-color: transparent;
                    border: none;

                    &:hover {
                        text-decoration: none;
                        opacity: .7;
                        transform: scale(1.5,1.5);
                        cursor: pointer;
                    }
                }

                @media (max-width: 540px) {
                    font-size: larger;
                    padding: 3px;
                }

                @media (max-width: 400px) {
                    font-size: small;
                    padding: 1px;
                }
            }
        }

        .each-slide {
            width: 100vw;
            height: 100vh;
            float: left;
            line-height: 100vh;
            font-size: 40vh;
            text-align: center;
            background-size: cover;
            background-position: center center;
            background-color: transparent;
            transition: transform 4s ease-in-out; /* Add transition property for smooth scaling */
        }
        
        .each-slide.active {
            transform: scale(1.1); /* Scale the active slide */
        }
    }

    .image-contant {
        position: absolute;
        top: 20%;
        left: 0;
        right: 0;
        z-index: 9995;
        color: white;

        .image-contant-inner {
            color: white;
            padding: 25px;

            .heading {
                font-size: 4.5rem;
                font-weight: 700;
                text-shadow: 2px 2px black;


                @media (max-width: 1095px) {
                    font-size: 4rem;
                }

                @media (max-width: 820px) {
                    font-size: 3rem;

                }

                @media (max-width: 540px) {
                    font-size: 2.6rem;
                }
            }

            .paragraph {
                font-size: 1.5rem;
                text-shadow: 1px 1px black;

                @media (max-width: 1095px) {
                    font-size: 1.5rem;
                }

                @media (max-width: 820px) {
                    font-size: 1.3rem;

                }

                @media (max-width: 540px) {
                    font-size: 1.2rem;
                }
            }
        }

        @media (max-width: 820px) {
            top: 20%;
        }

        @media (max-width: 540px) {
            top: 16%;
        }

        @media (max-width: 400px) {
            top: 5%;
        }
    }

    .slide-content {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s ease-out, transform 4s ease-out;
      }
      
      .slide-content.active {
        opacity: 0;
        transform: translateY(-90px);
      }
}