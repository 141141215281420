$background-color: hsl(305, 100%, 24%);
$background-color-hover: #53004c;

.PackagesComponent{
    margin: 70px;

    @media (max-width: 400px) {
        margin: 30px;
    }

    @media (max-width: 780px) {
        text-align: center; 
    }

    .heading{
        font-size: 2.3rem;
        font-weight: bold;
    }
    .heading-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .icon-left{
            color: white;
            background-color: $background-color;
            border: 1px $background-color solid;
            border-radius: 50%;
            padding: 4px;
            font-size:xx-large;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .icon-right{
            color: $background-color;
            border: 1px $background-color solid;
            border-radius: 50%;
            padding: 4px;
            font-size:xx-large;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media (max-width: 476px) {
            margin: 0;
          }
    }

    .container {
        width: 100%;
        height: auto;

        .packages{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;
            position: relative;

            &::-webkit-scrollbar {
                display: none;
              }
        }

        .badge-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
    
            .badge {
                display: flex;
                align-items: center;
                background-color: rgb(230, 230, 230);
                color: black;
                margin: 3px;
                font-weight: 500;
            }
        }

    }

        .text{
            font-size: larger;
            text-align: center;
            text-wrap: wrap;
        }

        .button{
            background-color: $background-color;
            color: white;
            border-radius: 20px;
            padding: 10px 25px 10px 25px;
            border: none;
    
            &:hover{
                background-color: $background-color-hover;
            }
        }

        .view-package-image{
            width: 40%;
            margin-right: 50px;

            @media (max-width: 780px) {
                width: 100%;
            margin-right: 0;

            }
        
        }

        .main-popup-container{
            padding: 5px;
            position: fixed;
            height: 110%;
            top: -9%;
            left: 0;
            right: 0;
            z-index: 999999998;
            border-radius: 20px;
            margin: 90px 20px;
            // overflow: auto;

            .popup-container{
                overflow: auto;
                height: 100%;
    
                .button-container{
                    margin: 20px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 999999999;
                    font-size: x-large;

                    .btn-close{
                        color: white !important;
                    }
                }
    
                .popup-content{
                    height: 85%;
                    overflow: auto;
            box-shadow: 0 6px 10px rgba(0, 0, 0, 0.50);

                }
            }
        }

      }
